// libraries
import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Button from "../../client/compositions/button"
import Block from "../../core/components/block"
import ContactForm from "../../client/compositions/contact-form"
import Checkbox from "../../core/components/checkbox"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Row from "../../core/components/row"
import Tagline from "../../client/compositions/tagline"
import {
  address,
  email,
  fax,
  telephone,
} from "../../client/elements/contact-details"
import { quickMenu } from "../../client/elements/menus"

/**
 * Place Order Page
 * @see https://zpl.io/adglle5
 */
function PlaceOrderPage() {
  return (
    <article id="page-contact" className="page-content light-quick-menu">
      {quickMenu}

      <Banner
        title={<>Get in touch</>}
        content={
          <>
            <p>
              Our team is ready to support you with the design of your project,
              the placement of orders, and the answering of all your questions.
              Don’t hesitate to get in touch with us in one of the different
              channels below:
            </p>
            <dl id="contact-options">
              <div className="contact-option">
                <dt className="title">Call us</dt>
                <dd className="content">
                  <Decoration image="contact-telephone.svg" className="icon" />
                  <p>{telephone}</p>
                </dd>
              </div>
              <div className="contact-option">
                <dt className="title">Email us</dt>
                <dd className="content">
                  <Decoration image="contact-email.svg" className="icon" />
                  <p>{email}</p>
                </dd>
              </div>

              <div className="contact-option">
                <dt className="title">Send a request</dt>
                <dd className="content">
                  <Decoration image="contact-form.svg" className="icon" />
                  <p>We're ready to help.</p>
                </dd>
              </div>
            </dl>
          </>
        }
        className="flipped contact-banner"
      >
        <DecorationFluid image="contact/banner.jpg" />
      </Banner>

      <Row id="place-order">
        <Block className="block-content">
          <ContactForm
            submitButton={
              <Button type="submit" image="icons/arrow-right.svg">
                Send Request
              </Button>
            }
          >
            <div className="fields">
              <label className="enter-name">
                <span className="label">Full Name</span>
                <input type="text" name="name" placeholder="Full Name" />
              </label>
              <label className="enter-company">
                <span className="label">Company</span>
                <input type="text" name="company" placeholder="Company" />
              </label>
              <label className="enter-email">
                <span className="label">Email (required)</span>
                <input
                  type="email"
                  name="email"
                  placeholder="Email*"
                  required
                />
              </label>
              <label className="enter-telephone">
                <span className="label">Phone</span>
                <input type="tel" name="telephone" placeholder="Phone" />
              </label>
              <label className="enter-message">
                <span className="label">Message (required)</span>
                <textarea
                  name="message"
                  placeholder="Your Message*"
                  maxLength={500}
                  required
                  rows={1}
                />
              </label>
              <div className="agreements">
                <Checkbox name="agree-terms" required>
                  I accept the processing of personal data
                </Checkbox>
              </div>
            </div>
          </ContactForm>
        </Block>
      </Row>

      <Row id="contact">
        <Block className="block-content">
          <dl className="items">
            <div className="item">
              <dt className="title">Find us</dt>
              <dd className="content">{address}</dd>
            </div>
            <div className="item">
              <dt className="title">Contact us</dt>
              <dd className="content">Phone: {telephone}</dd>
              <dd className="content">Fax: {fax}</dd>
              <dd className="content">Email: {email}</dd>
            </div>
            <div className="item">
              <dt className="title">Support</dt>
              <dd>
                <Navigation>
                  <Anchor to="/faq">FAQ</Anchor>
                  <Anchor to="/warranty">Warranty</Anchor>
                  <Anchor to="/resources">Resources</Anchor>
                </Navigation>
              </dd>
            </div>
          </dl>
        </Block>
      </Row>
      <Tagline />
    </article>
  )
}

export default PlaceOrderPage
