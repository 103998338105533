import React from "react"
import LazyLoad from "react-lazyload"

/**
 * withLazyLoad()
 *
 * Lazy load a component when it appears on screen
 *
 */
export default function withLazyLoad(WrappedComponent, lazyloadProps) {
  return (props) => {
    const { disableLazyLoad, ...passthruProps } = props

    if (disableLazyLoad) {
      return (
        <span className="disabled-lazy-load-wrapper">
          <WrappedComponent {...passthruProps} />
        </span>
      )
    }

    return (
      <LazyLoad {...lazyloadProps}>
        <WrappedComponent {...passthruProps} />
      </LazyLoad>
    )
  }
}
