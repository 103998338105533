// libraries
import React from "react"
import classNames from "react-css-module-classnames"
import DOMPurify from "dompurify"

// built-in parsers
const inputParsers = {
  uppercase(input) {
    return (input || "").toUpperCase()
  },
  email(input) {
    return (input || "").toLowerCase()
  },
  telephone(input) {
    return (input || "").replace(/[^0-9\(\)\-\+]/g, "")
  },
  url(input) {
    try {
      return new URL(input).toString() || ""
    } catch (err) {
      return ""
    }
  },
}

/**
 * <ContactForm />
 * @see https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f
 */
class ContactForm extends React.Component {
  constructor() {
    super()

    // default state
    this.state = {
      result: null,
      msg: null,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(event) {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)

    form.classList.add("submitted")

    for (let name of data.keys()) {
      const input = form.elements[name]
      const parserName = input.dataset.parse

      if (parserName) {
        const parser = inputParsers[parserName]
        const parsedValue = parser(data.get(name))
        data.set(name, parsedValue)
      }
    }

    const payload = {}
    for (let key of data.keys()) {
      payload[key] = data.get(key)
    }

    if (!payload["agree-terms"])
      return alert(
        "You must agree with our terms and conditions to submit this form."
      )

    let response = {
      result: "error",
      msg: "No handler defined",
    }
    if (typeof this.props.handler === "function") {
      response = await this.props.handler(payload)
    }

    let { result, msg } = response

    // sanitize response
    if (!["success", "error"].includes(result)) {
      console.warn("weird response", result, msg)
      result = "error"
      msg = "Something went wrong. Please try again later."
    }

    this.setState({
      result,
      msg: DOMPurify.sanitize(msg),
    })
  }

  render() {
    const { children, submitButton } = this.props
    const { result, msg } = this.state
    return (
      <form
        onSubmit={this.onSubmit}
        noValidate
        {...classNames("contact-form").plus(result)}
      >
        {children}
        <fieldset className="submit">
          {result !== "success" ? (
            <>
              {submitButton || <button type="submit">Submit</button>}
              {result === "error" && msg && (
                <span className="error-msg">{msg}</span>
              )}
            </>
          ) : (
            <span className="success-msg">{msg}</span>
          )}
        </fieldset>
      </form>
    )
  }
}

export default ContactForm
