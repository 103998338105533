import axios from "axios"
import { default as ContactFormComponent } from "../../core/components/contact-form"
import withHandler from "../../core/composers/handler"

const MAIL_HANDLER = "https://invinitywinerack.com/mail/send.php"

// handlers
const sendDataToEmailAddress = async function (...args) {
  console.log("handle with", args)

  // get response
  let response
  try {
    response = await axios({
      method: "post",
      url:
        MAIL_HANDLER +
        "?t=" +
        new Date().getTime() +
        "&n=" +
        ~~(Math.random() * 100),
      data: args,
    })

    return {
      result: "success",
      msg: "Many thanks for your message - we'll be in touch soon!",
    }
  } catch (err) {
    if (err.isAxiosError) {
      console.log(err.toJSON())
      return {
        result: "error",
        msg: err.toJSON().message,
      }
    }

    return {
      result: "error",
      msg: "Check internet connection",
    }
  }
}

/**
 * Create contact with mailchimp service.
 */
const ContactForm = withHandler(
  ContactFormComponent,
  null,
  sendDataToEmailAddress
)

/**
 * Exports
 */
export default ContactForm
