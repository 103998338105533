// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Collage />
type Props = {
  /** Number of cells wide */
  width: number,
  /** Number of cells tall */
  height: number,
  /** Grid X / Y / Z / Span X / Span Y */
  positions: Array<{
    x: number,
    y: number,
    z: number,
    w: number,
    h: number,
    align: string,
    justify: string,
  }>,
  /** Cell W / H */
  sizes?: Array<{ w: number | string, h: number | string }>,
  /** Content */
  children: any,
  /** Custom class for root element */
  className?: string,
  ...
}

/**
 * Collage
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .collage         | Root element                                           |
 * | .collage-items   | List of collage items                                  |
 * | .collage-item    | Single collage item                                    |
 * |------------------|--------------------------------------------------------|
 */
class Collage extends React.Component<Props> {
  static defaultProps = {
    positions: [],
  }

  // react methods

  render() {
    let {
      width,
      height,
      positions,
      sizes,
      styles,
      children,
      className,
      ...divProps
    } = this.props

    return (
      <div {...divProps} {...classNames("collage").plus(className)}>
        <ul
          {...classNames("collage-items")}
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${width}, ${100 / width}%)`,
            gridTemplateRows: `repeat(${height}, ${100 / height}%)`,
            width: "100%",
            height: "100%",
          }}
        >
          {React.Children.map(children, (child, i) => {
            let { x, y, z, w, h, align: alignSelf, justify: justifySelf } =
              (positions && positions[i]) || {}
            let { w: width, h: height } = (sizes && sizes[i]) || {}
            let style = styles && styles[i]

            // ignore elements with zero width/height
            if (!x) x = 0
            if (!y) y = 0
            if (!z) z = 0
            if (!w) w = 1
            if (!h) h = 1
            if (!width) width = "100%"
            if (!height) height = "100%"
            if (!alignSelf) alignSelf = "center"
            if (!justifySelf) justifySelf = "center"

            return (
              <li
                key={i}
                {...classNames("collage-item")}
                style={Object.assign(
                  {
                    position: "relative",
                    overflow: "hidden",
                    gridColumn: `${x + 1} / span ${w}`,
                    gridRow: `${y + 1} / span ${h}`,
                    zIndex: `${z}`,
                    width,
                    height,
                    alignSelf,
                    justifySelf,
                  },
                  style
                )}
              >
                {child}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

/**
 * Exports
 */
export default Collage
