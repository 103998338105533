// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gatsby
import Anchor from "./anchor"
import Decoration from "./decoration"

// <Checkbox />
type Props = {
  /** Checkbox type */
  type: "radio" | "checkbox",
  /** Initial checked state */
  checked: boolean,
  /** Check content */
  checkSymbol: any,
  /** Content */
  children?: any,
  /** Custom class for root element */
  className?: string,
}

/**
 * A custom checkbox
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .checkbox        | Root element                                           |
 * |------------------|--------------------------------------------------------|
 */
class CheckboxComponent extends React.Component<Props> {
  static defaultProps = {
    type: "checkbox",
    checkSymbol: "✕",
  }

  state = {
    checked: false,
  }

  handleChange(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return
    }

    const { checked } = event.target
    this.setState({ checked })
  }

  // react methods

  componentDidMount() {
    const { checked } = this.props
    this.setState({ checked })
  }

  render() {
    let {
      type,
      checkSymbol,
      children,
      className,
      ...checkboxProps
    } = this.props
    let { checked } = this.state

    return (
      <label
        {...classNames("checkbox")
          .plus(className)
          .plus(checked ? "checked" : "")}
        style={{
          // align checkbox next to label
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-start",
          alignItems: "center",

          // prevent selecting text
          "-webkit-touch-callout": "none",
          "-webkit-user-select": "none",
          "-khtml-user-select": "none",
          "-moz-user-select": "none",
          "-ms-user-select": "none",
          userSelect: "none",
        }}
      >
        <input
          type={type}
          {...checkboxProps}
          checked={checked}
          onChange={this.handleChange.bind(this)}
        />
        <div
          className="box"
          style={{
            // set width/height of checkbox
            width: "var(--line-height, 20px)",
            height: "var(--line-height, 20px)",
            margin: "calc(var(--line-height, 20px)/2)",
            border: "1px solid currentColor",
            flexShrink: 0,
            flexGrow: 0,

            // checkbox symbol aligns in center
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          {checked && (
            <div
              className="check"
              style={{
                width: "var(--line-height, 20px)",
                height: "var(--line-height, 20px)",
                fontSize: "var(--line-height, 20px)",
                lineHeight: "var(--line-height, 20px)",
              }}
            >
              {checkSymbol}
            </div>
          )}
        </div>
        <div className="label">{children}</div>
      </label>
    )
  }
}

/**
 * Exports
 */
export default CheckboxComponent
