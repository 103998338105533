import DecorationComponent from "../../core/components/decoration"
import withData from "../../core/composers/data"
import withLazyLoad from "../../core/composers/lazyload"
import useImagesDirect from "../../client/queries/images-direct"

/**
 * Compose
 */
const Decoration = withLazyLoad(
  withData(
    DecorationComponent,
    ({ metadata, imagesDirect }) => ({
      images: imagesDirect,
    }),
    useImagesDirect
  ),
  {
    once: true,
    offset: 100,
  }
)

export default Decoration
