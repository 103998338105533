import React from "react"
import Anchor from "../../core/components/anchor"
import Button from "../../client/compositions/button"
import Collage from "../../core/components/collage"
import Block from "../../core/components/block"
import Row from "../../core/components/row"

/** Banner component */
function BannerComponent(props) {
  const {
    title,
    content,
    showScroll,
    children,
    className,
    ...passthruProps
  } = props

  const numChildren = React.Children.toArray(children).length

  return (
    <Row
      id="banner"
      className={`banner children-${numChildren} ${className}`}
      role="banner"
      {...passthruProps}
    >
      {children && (
        <Block className="block-media">
          {numChildren === 1 ? (
            children
          ) : (
            <Collage
              width={8}
              height={8}
              positions={[
                { x: 1, y: 0, w: 2, h: 2, align: "start", justify: "start" },
                { x: 7, y: 3, w: 2, align: "center", justify: "end" },
                { x: 6, y: 6, w: 2, h: 3, align: "end", justify: "end" },
                { x: 0, y: 5, h: 2, align: "end", justify: "start" },
              ]}
              sizes={[
                { w: "260px", h: "240px" },
                { w: "340px", h: "240px" },
                { w: "260px", h: "340px" },
                { w: "340px", h: "240px" },
              ]}
              style={{ height: "100vh" }}
            >
              {children}
            </Collage>
          )}
        </Block>
      )}
      <Block className="block-content">
        {title && <h1 className="title">{title}</h1>}
        {content && <div className="content">{content}</div>}
      </Block>
      {showScroll && (
        <Button
          scroll="below-fold"
          image="icons/arrow-right.svg"
          className="scroll"
        >
          Scroll
        </Button>
      )}
    </Row>
  )
}

/**
 * Compose
 */
const Banner = BannerComponent

export default Banner
